/* self-executing anonymous function */
(function(window) {

	// console.log('ShareUtils');
	/* constructor */
	/**
	 * Javascript OOP Helper Class that facilitates sharing urls:
	 *
	 * var share = new Object();
	 * share.link = 'http://www.google.com';
	 * share.caption = 'A handy website for finding answers';
	 * share.description = 'What else can I say about this subject…';
	 * share.picture_arr = new Array(absolute_path_to_pic_1, path_to_pic_2, path_to_pic_3);
	 *
	 * share on Twitter (hashtags is a comma-separated list of hashtags, don't include the #-sign)
	 * <code>
	 * var shareUtils = new ShareUtils(450, 340, false);
	 * shareUtils.twitter(share.link, share.description, share.hashtags);
	 * </code>
	 *
	 * share on Facebook
	 * <code>
	 * var shareUtils = new ShareUtils(650, 380, true);
	 * shareUtils.facebook(share.link, share.caption, share.description, share.picture_arr);
	 * </code>
	 *
	 * to be continued...
	 */
	function ShareUtils(popupWidth, popupHeight, center) {
		this.popupWidth = popupWidth;
		this.popupHeight = popupHeight;
		this.center = center;
	}

	/* private methods */
	function getLeft(context) {
		if (context.center === true)
			return window.screenX + window.innerWidth / 2 - context.popupWidth / 2;
		else
			return window.screenX;
	}

	function getTop(context) {
		if (context.center === true)
			return window.screenY + window.innerHeight / 2 - context.popupHeight / 2;
		else
			return window.screenY;
	}

	/* public methods */
	ShareUtils.prototype.twitter = function(url, descr, hashtags) {
		// console.log('twitter');

		url = 'http://twitter.com/share?url=' + url + '&text=' + descr + '&hashtags=' + hashtags;
		props = '' + 'width=' + this.popupWidth + ',' + 'height=' + this.popupHeight + ',' + 'toolbar=no,' + 'location=no,' + 'scrollbars=no,' + 'status=no,' + 'resizable=yes,' + 'fullscreen=no,' + 'top=' + getTop(this) + ',' + 'left=' + getLeft(this);

		window.open(url, 'Twitter', props);
	};

	ShareUtils.prototype.facebook = function(url, title, description, images) {
		// console.log('facebook');

		if (!title && !description && !images) {
			/* using url only (requires og tags) */
			url = encodeURIComponent(url);
			url = 'http://www.facebook.com/sharer.php?u=' + url;
		} else {
			/* using p[url] only (overrides og tags) */
			var p = '&p[url]=' + encodeURIComponent(url);

			if (title)
				p += '&p[title]=' + encodeURIComponent(title);
			if (description)
				p += '&p[summary]=' + encodeURIComponent(description);
			if (images) {
				for (var i = 0; i < images.length; i++) {
					//console.log(images[i]);
					p += '&p[images][' + i + ']=' + encodeURIComponent(images[i]);
				}
			}
			url = 'http://www.facebook.com/sharer.php?s=100' + p;
		}

		props = '' + 'width=' + this.popupWidth + ',' + 'height=' + this.popupHeight + ',' + 'toolbar=no,' + 'location=no,' + 'scrollbars=no,' + 'status=no,' + 'resizable=yes,' + 'fullscreen=no,' + 'top=' + getTop(this) + ',' + 'left=' + getLeft(this);

		window.open(url, 'Facebook', props);
	};

	window.ShareUtils = ShareUtils;

}(window));