/*********************************************************
	* CORE module
	* Manage modules & utilities
	* v1
/*********************************************************/

var CORE = (function() {
	"use strict";

	/*********************************************************
		* Private Variables
	/*********************************************************/
	var PHPvars = {
		language: "en",
		baseURL: "/",
		user: ""
	}

	var _js_vars = PHPvars;
	var _utils = {};
	var _controllers = {};

	/*********************************************************
		* Public Variables
	/*********************************************************/
	var app_language = _js_vars.language;
	var app_baseURL = _js_vars.baseURL;
	var app_user = _js_vars.user;



	$(function() {
		init();
	});

	/*********************************************************
		* Private Functions
	/*********************************************************/

	function _initUtils() {
		/*********************************************************
			* Utilities go here 
		/*********************************************************/

	}

	function _initControllers() {

		/*********************************************************
			* Modules go here 
		/*********************************************************/

		_controllers.ui = new UIController({
			'exec': true
		});

		log('%c CORE :: Controllers initialized', 'color: #009248; font-weight: bold;');
	}

	/*********************************************************
		* Global Functions
	/*********************************************************/

	function init() {
		log('%c CORE :: initialized', 'color: #009248; font-weight: bold;');
		_initUtils();
		_initControllers();
	}

	var jsvars = function() {
		return _js_vars;
	}

	var utils = function() {
		return _utils;
	}

	var controllers = function() {
		return _controllers;
	}

	return {
		'init': init,
		'jsvars': jsvars,
		'utils': utils,
		'controllers': controllers,
		'language': app_language,
		'baseURL': app_baseURL,
		'user': app_user,
	};

})(CORE);