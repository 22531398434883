var LocalStorageService = function() {

	var _available,
		_appState;

	function init() {
		_available = isSupported();
		log('LocalStorageService::init available:', _available);
	}

	function isSupported() {
		try {
			localStorage.test = 2; // try to use localStorage      
		} catch (e) { //there was an error so...
			// alert('You are in Private Mode\nPlease deactivate Private Mode and then reload the page.');
			return false;
		}
		return (typeof(Storage) !== "undefined");
	}

	function getAppState() {
		if (!_available) {
			return parseInt(_appState);
		}
		return parseInt(localStorage.getItem('app_state'));
	}

	function setAppState(value) {
		_appState = value;
		if (!_available) return;
		localStorage.setItem('app_state', value);
	}

	return {
		'init': init,
		'getAppState': getAppState,
		'setAppState': setAppState,
	};
};