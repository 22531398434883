function get_short_url(long_url, return_func) {
	$.getJSON(
		"http://api.bitly.com/v3/shorten?callback=?", {
			"format": "json",
			"apiKey": '20963042f94db568ca5dfbb13d27185015a91f3f',
			"login": 'o_bqjrea9og',
			"longUrl": long_url
		},
		function(response) {
			return_func(response.data.url);
		}
	);
}