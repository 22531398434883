var UIController = function(params) {

	var is3dInitialized = false;
	var originalCubePosition = { x: 22, y: 167 };
	var previousCubePosition = { x: 0, y: 0 };
	var previousMousePosition = { x: 0, y: 0 };
	animEndEventNames = {
		'WebkitAnimation': 'webkitAnimationEnd',
		'OAnimation': 'oAnimationEnd',
		'msAnimation': 'MSAnimationEnd',
		'animation': 'animationend'
	};
	// animation end event name
	animEndEventName = animEndEventNames[Modernizr.prefixed('animation')];
	transEndEventNames = {
		'WebkitTransition': 'webkitTransitionEnd',
		'OTransition': 'oTransitionEnd',
		'MozTransition': 'transitionend',
		'transition': 'transitionend'
	};
	// transition end event name
	transEndEventName = transEndEventNames[Modernizr.prefixed('transition')];

	if (params.exec) {
		init();
	}

	function init() {
		log('%c UI-CONTROLLER :: initialized', 'color: #04a9ff;');

		// Check if preserve-3D is supported
		if (Modernizr.preserve3d) {
			// supported
		} else {
			// not-supported
		}

		/** Manage mouvements */
		var touchX, touchY, go;

		$(".container_cube").on(transEndEventName, function(e) {
			openText();
			if (is3dInitialized == false) {
				is3dInitialized = true;
				startDragging();
			}
		});
		$(".container_cube").on(animEndEventName, function(e) {
			if ($(this).hasClass("startAnim_7")) {
				$(".container_cube .t").removeClass("object_grey");
				$(".container_cube").removeClass("startAnim_7");
				$(".container_cube").css("opacity", 1);
				$(".container_cube .t").addClass("startAnim_t");
			}
		});
		$(".container_cube .t").on(animEndEventName, function(e) {
			if ($(this).hasClass("startAnim_t")) {
				initialize3D();
			}
		});

		function prevent(e){
			e.preventDefault();
		}

		function move(e) {
			var x;
			var y;
			if (e.type == "mousemove") {
				x = e.pageX;
				y = e.pageY;
			} else {
				x = e.touches[0].pageX;
				y = e.touches[0].pageY;
			}

			var newX = previousCubePosition.x + (x - previousMousePosition.x);
			var newY = previousCubePosition.y + (y - previousMousePosition.y);

			previousMousePosition = {
				x: x,
				y: y
			};
			previousCubePosition = {
				x: newX,
				y: newY
			};

			$(".container_cube").css("transform", "rotateY(" + newX + "deg) rotateX(" + newY + "deg)");
		}

		function start(e) {
			go = false;
			closeText();
			previousMousePosition.x = e.touches[0].pageX;
			previousMousePosition.y = e.touches[0].pageY;
			$(".container_cube").removeClass("animate");
			website.addEventListener("touchmove", prevent, false);
		}

		function stop(e) {
			website.removeEventListener("touchmove", prevent, false);
			initialize3D();
		}

		function startDragging() {
			website.addEventListener("touchstart", start, true);
			website.addEventListener("touchmove", move, true);
			website.addEventListener("touchend", stop, true);
			website.addEventListener("touchleave", stop, true);
			website.addEventListener("touchcancel", stop, true);
			website.addEventListener("mousedown", mDown);
			website.addEventListener("mouseup", mUp);
		}

		function mDown(e) {
			previousMousePosition.x = e.pageX;
			previousMousePosition.y = e.pageY;
			$(".container_cube").removeClass("animate");
			website.addEventListener("mousemove", move);
			closeText();
		}
		function mUp(e) {
			website.removeEventListener("mousemove", move);
			initialize3D();
		}

		/** Start animation */
		$(".container_cube").css("transform", "rotateY(1deg) rotateX(178deg)");
		$(".container_cube").css("opacity", 0);
		$("#world").show();
		$(".container_cube").addClass("startAnim_7");

		function initialize3D(t, d) {
			$(".container_cube").addClass("animate");
			$(".container_cube").css("transform", "rotateY(" + originalCubePosition.x + "deg) rotateX(" + originalCubePosition.y + "deg)");
			previousCubePosition.x = originalCubePosition.x;
			previousCubePosition.y = originalCubePosition.y;
		}

		function openText() {
			$("#circle1").addClass("scale-bezier");
			$("#circle2").addClass("scale");
			$("#line").addClass("open");
		}
		function closeText() {
			$("#circle1").removeClass("scale-bezier");
			$("#circle2").removeClass("scale");
			$("#line").removeClass("open");
		}
	}

	return {
		'init': init
	};
};