if (typeof console === "undefined") {
	this.console = {
		log: function() {}
	};
	this.console.log.apply = function() {};
}

var logEnabled = window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('agency') > -1;

function log() {
	if (!logEnabled) return;
	if (isIE()) {
		console.log(arguments[0]);
	} else {
		if (Function.prototype.bind) {
			window.log = Function.prototype.bind.call(console.log, console);
		} else {
			window.log = function() {
				Function.prototype.apply.call(console.log, console, arguments);
			};
		}
	}

}

function enableLogging() {
	logEnabled = true;
	return 'Logging enabled.';
}

function getUA() {
	return navigator.userAgent.toLowerCase();
}

function isIE() {
	var ua = getUA();
	return (ua.indexOf('msie') != -1) ? parseInt(ua.split('msie')[1]) : false;
}