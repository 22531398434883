var FacebookService = function(p_permissions, p_me_fields) {

	var _status = null,
		_me = null,
		_accessToken = null,
		// attempts to login the user, when an accessToken has been obtained
		_autoLogin = true,
		// automatically refreshes the page when _autoLogin has successfuly completed
		_autoRefresh = false,
		// gets dispatched when the user connects with the app
		_loginHandler = new signals.Signal(),
		_permissions = typeof p_permissions !== 'undefined' ? p_permissions : [],
		_me_fields = typeof p_me_fields !== 'undefined' ? p_me_fields : [];


	function init(p__autoLogin, p__autoRefresh) {
		log('%c FacebookService :: initialized, app_id:', 'color: #3b5998;', CORE.jsvars().fb_app_id);
		log('%c FacebookService :: p__autoLogin:', 'color: #3b5998;', p__autoLogin);
		log('%c FacebookService :: p__autoRefresh:', 'color: #3b5998;', p__autoRefresh);
		log('%c FacebookService :: _permissions:', 'color: #3b5998;', _permissions);
		log('%c FacebookService :: _me_fields:', 'color: #3b5998;', _me_fields);

		_autoLogin = typeof p__autoLogin !== 'undefined' ? p__autoLogin : _autoLogin;
		_autoRefresh = typeof p__autoRefresh !== 'undefined' ? p__autoRefresh : _autoRefresh;

		loginStatusSignal.add(loginStatusHandler);
	}

	function login(e) {
		if (e) {
			e.preventDefault();
		}
		var options = {};

		options.scope = p_permissions;
		// options.return_scopes = true;

		// if (p_rerequest !== undefined) {
		// 	options.auth_type = 'rerequest';
		// }
		FB.login(loginStatusHandler, options);
	}

	function loginStatusHandler(response) {
		log('%c FacebookService :: loginStatusHandler ', 'color: #3b5998;', response);
		_status = response.status;

		if (response.status === 'connected') {
			_accessToken = response.authResponse.accessToken;
			FB.api('/me', {
				fields: _me_fields.join()
			}, function(me) {
				_me = me;
				if (_autoLogin && !CORE.user) {
					// try to login using access token
					$.post('/' + CORE.jsvars().language + '/user/login/facebook', {
						accesstoken: _accessToken
					}, function(response) {
						log('%c FacebookService :: server login response:', 'color: #3b5998;', response);
						if (response.success) {
							if (_autoRefresh) {
								window.location.reload();
							} else {
								CORE.user = response.user;
								_loginHandler.dispatch({
									accessToken: _accessToken,
									me: _me,
									user: CORE.user,
								});
							}
						} else {
							_loginHandler.dispatch({
								accessToken: _accessToken,
								me: _me,
								user: CORE.user,
							});
						}
					}, 'json');
				} else {
					_loginHandler.dispatch({
						accessToken: _accessToken,
						me: _me,
						user: CORE.user,
					});
				}
			});
		} else {
			_loginHandler.dispatch({
				accessToken: _accessToken,
				me: _me,
				user: CORE.user,
			});
		}
	}

	return {
		'init': init,
		'login': login,
		'loginHandler': _loginHandler,
		'getStatus': function() {
			return _status
		},
		'getUser': function() {
			return _me
		},
	};



};